<template>
    <b-modal title="Upgrade Terminal Firmware" v-model="upgradeTerminalFirmwareModalActive" no-close-on-backdrop>
        <template #default>

            <b-form-group>
                <label>Upgrade package URL</label>
                <b-form-input v-model="packageURL"/>
            </b-form-group>
        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="upgradeTerminalFirmwareModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="upgradeFirmware">
                <feather-icon
                    icon="PlayIcon"
                    class="mr-50"
                />
                <span class="align-middle">Upgrade Firmware</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BModal, BFormInput} from 'bootstrap-vue'

    export default  {
        components: {
            BButton,
            BFormGroup,
            BModal,
            BFormInput
        },
        data() {
            return {

                packageURL: '',
                terminalId: '',
                upgradeTerminalFirmwareModalActive: false

            }
        },
        methods: {
            open(terminalId) {
                this.terminalId = terminalId
                this.upgradeTerminalFirmwareModalActive = true
            },
            upgradeFirmware() {
                const thisIns = this
                const assignPromise = this.$http.post(`/api/terminal/v1/terminal/${  this.terminalId  }/upgrade_firmware`, {
                    upgrade_package_url: this.packageURL
                })
                assignPromise.then(function() {
                    thisIns.$printSuccess('Firmware upgrade requested')
                    thisIns.$emit('firmwareUpgradeStarted')
                    thisIns.upgradeTerminalFirmwareModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        }
    }
</script>