<template>
    <b-modal title="Assign terminal" v-model="terminalAssignModalActive" no-close-on-backdrop>
        <template #default>

            <b-form-group>
                <label>Project</label>
                <v-select
                    v-model="selectedProject"
                    :reduce="project => project.id"
                    label="name"
                    :options="projects"
                />
            </b-form-group>
        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="terminalAssignModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="assignTerminal">
                <feather-icon
                    icon="RepeatIcon"
                    class="mr-50"
                />
                <span class="align-middle">Assign</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BModal} from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default  {
        components: {
            BButton,
            BFormGroup,
            BModal,
            vSelect
        },
        data() {
            return {

                projectsRaw: [],
                selectedProject: '',
                terminalId: '',
                terminalAssignModalActive: false

            }
        },
        methods: {
            open(terminalId) {
                this.terminalId = terminalId
                this.terminalAssignModalActive = true
            },
            loadData() {
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/project')
                loadPromise.then(function(response) {
                    thisIns.projectsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            assignTerminal() {
                const thisIns = this
                const assignPromise = this.$http.put(`/api/management/v1/terminal/${  this.terminalId  }/assign/${  this.selectedProject}`)
                assignPromise.then(function() {
                    thisIns.$printSuccess('Terminal assigned')
                    thisIns.$emit('terminalAssigned')
                    thisIns.terminalAssignModalActive = false
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        },
        computed: {
            projects() {
                return this.projectsRaw.map(project => {
                    return {
                        id: project.id,
                        name: `${project.client  } - ${  project.name}`
                    }
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>