<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Terminals">

                <b-button variant="black2" @click="$refs.addModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>

                <basic-table :columns="columns" :data="terminals" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <TerminalActionsButton
                            :data="props.row"
                            v-on:dataChanged="loadData"
                            v-on:openTerminalAssignment="$refs.assignmentModal.open(props.row.id)"
                            v-on:openTerminalFirmwareUpgradeRequest="$refs.upgradeTerminalFirmwareModal.open(props.row.id)"
                        />
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <span v-if="props.row.terminal_model_type === 0"><feather-icon size="22" icon="HardDriveIcon" class="mr-1"/>  {{ props.formattedRow[props.column.field] }} </span>
                        <span v-else><feather-icon size="22" icon="MonitorIcon" class="mr-1"/>  {{ props.formattedRow[props.column.field] }} </span>
                    </div>
                    <div v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.online" variant="success">Online</b-badge>
                        <b-badge v-else variant="light-danger">Offline</b-badge>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>

            </b-card>
        </b-overlay>

        <AddTerminalModal ref="addModal" :terminal-models="terminalModels" v-on:itemAdded="loadData"/>
        <AssignTerminalModal ref="assignmentModal" v-on:terminalAssigned="loadData"/>
        <UpgradeTerminalFirmwareModal ref="upgradeTerminalFirmwareModal"/>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay, BBadge} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddTerminalModal from '@/views/Terminal/AddTerminalModal'
    import AssignTerminalModal from '@/views/Terminal/AssignTerminalModal'
    import TerminalActionsButton from '@/views/Terminal/TerminalActionsButton'
    import UpgradeTerminalFirmwareModal from '@/views/Terminal/UpgradeTerminalFirmwareModal'

    export default {
        components: {
            UpgradeTerminalFirmwareModal,
            TerminalActionsButton,
            AssignTerminalModal,
            AddTerminalModal,
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BBadge
        },
        data() {
            return {
                dataLoaded: false,
                terminalsRaw: [],
                terminalModels: [],
                onlineTerminals: [],

                columns: [
                    {
                        label: 'MAC',
                        displayType: 0,
                        field: 'mac',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Project',
                        displayType: 0,
                        field: 'project',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Client',
                        displayType: 0,
                        field: 'client',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Additional Info',
                        displayType: 0,
                        field: 'additional_info',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Terminal model',
                        displayType: 2,
                        field: 'terminal_model',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Status',
                        displayType: 3,
                        field: 'status',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Last seen',
                        displayType: 0,
                        field: 'last_seen',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                onlineRefreshInterval: null
            }
        },
        methods: {
            loadData() {

                this.refreshOnlineStatuses()

                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/terminal')
                loadPromise.then(function(response) {
                    thisIns.terminalsRaw = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const terminalModelsPromise = this.$http.get('/api/management/v1/terminal_model')
                terminalModelsPromise.then(function(response) {
                    thisIns.terminalModels = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, terminalModelsPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            refreshOnlineStatuses() {
                const thisIns = this
                const onlineTerminalsPromise = this.$http.get('/api/terminal/v1/status/online_terminals')
                onlineTerminalsPromise.then(function(response) {
                    thisIns.onlineTerminals = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        },
        computed: {
            terminals() {
                return this.terminalsRaw.map((terminal, index) => {
                    return {
                        index,
                        id: terminal.id,
                        mac: terminal.mac,
                        project_id: terminal.project_id,
                        project: (terminal.project.length > 0) ? terminal.project : 'Not assigned',
                        client: (terminal.project.length > 0) ? terminal.client : 'Not assigned',
                        last_seen: (terminal.first_connection_made) ? this.moment(terminal.last_seen).fromNow() : 'Never',
                        online: this.onlineTerminals.includes(terminal.id),
                        status: (this.onlineTerminals.includes(terminal.id)) ? 'Online' : 'Offline',
                        additional_info: terminal.additional_info,
                        terminal_model_id: terminal.terminal_model_id,
                        terminal_model: terminal.terminal_model,
                        terminal_model_type: terminal.terminal_model_type
                    }
                })
            }
        },
        mounted() {
            this.loadData()
            this.onlineRefreshInterval = setInterval(this.refreshOnlineStatuses, 5000)
        },
        beforeDestroy() {
            clearInterval(this.onlineRefreshInterval)
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
</style>