<template>
    <b-modal title="Add terminal" v-model="addTerminalModalActive" no-close-on-backdrop>
        <template #default>

            <b-form-group>
                <label>Terminal model</label>
                <v-select
                    v-model="addObject.terminal_model_id"
                    :reduce="model => model.id"
                    label="name"
                    :options="terminalModels"
                />
            </b-form-group>

            <b-form-group>
                <label>MAC</label>
                <cleave
                    id="mac"
                    v-model="addObject.mac"
                    class="form-control"
                    :raw="false"
                    :options="cleaveConfig"
                    placeholder="XX:XX:XX:XX:XX:XX"
                />
            </b-form-group>

            <b-form-group>
                <label>Additional info</label>
                <b-form-input v-model="addObject.additional_info"/>
            </b-form-group>

        </template>

        <template #modal-footer>

            <b-button variant="danger" @click="addTerminalModalActive = false">
                <feather-icon
                    icon="XIcon"
                    class="mr-50"
                />
                <span class="align-middle">Close</span>
            </b-button>

            <b-button variant="primary" @click="addTerminal" :disabled="!((new RegExp('[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}:[0-9A-F]{2}')).test(addObject.mac))">
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">Add</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>

    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import Cleave from 'vue-cleave-component'
    import vSelect from 'vue-select'

    export default {
        props: {
            terminalModels: {
                type: Array,
                required: true
            }
        },
        components: {
            BButton,
            BFormGroup,
            BFormInput,
            BModal,
            Cleave,
            vSelect
        },
        data() {
            return {

                addTerminalModalActive: false,
                addObject: {
                    terminal_model_id: '',
                    mac: '',
                    additional_info: ''
                },


                cleaveConfig: {
                    delimiter: ':',
                    blocks: [2, 2, 2, 2, 2, 2],
                    uppercase: true
                }

            }
        },
        methods: {
            open() {
                this.addTerminalModalActive = true
            },
            addTerminal() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/terminal', this.addObject)
                addPromise.then(function() {
                    thisIns.$printSuccess('Terminal added')
                    thisIns.addTerminalModalActive = false
                    thisIns.addObject = {
                        mac: '',
                        additional_info: ''
                    }
                    thisIns.$emit('itemAdded')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            }
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>