<template>
    <b-dropdown text="Open" variant="outline-black2" size="sm" split right>
        <b-dropdown-item @click="openInNewTab()">
            <feather-icon
                icon="PlusCircleIcon"
                size="16"
            />
            <span class="ml-75">Open in new tab</span>
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item v-if="isNullObjectId(data.project_id)" @click="$emit('openTerminalAssignment')">
            <feather-icon
                icon="RepeatIcon"
                size="16"
            />
            <span class="ml-75">Assign</span>
        </b-dropdown-item>
        <b-dropdown-item v-else @click="unassignTerminal()">
            <feather-icon
                icon="RepeatIcon"
                size="16"
            />
            <span class="ml-75">Unassign</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.online" @click="openShell()">
            <feather-icon
                icon="TerminalIcon"
                size="16"
            />
            <span class="ml-75">Open shell</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.online" @click="rebootTerminal()">
            <feather-icon
                icon="RefreshCwIcon"
                size="16"
            />
            <span class="ml-75">Reboot</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.online" @click="getScreenshot()">
            <feather-icon
                icon="CameraIcon"
                size="16"
            />
            <span class="ml-75">Get screenshot</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="data.online" @click="$emit('openTerminalFirmwareUpgradeRequest')">
            <feather-icon
                icon="CpuIcon"
                size="16"
            />
            <span class="ml-75">Upgrade Firmware</span>
        </b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item variant="danger" @click="removeTerminal()">
            <feather-icon
                icon="Trash2Icon"
                size="16"
            />
            <span class="ml-75">Remove</span>
        </b-dropdown-item>
    </b-dropdown>
</template>
<script>
    import {BDropdown, BDropdownDivider, BDropdownItem} from 'bootstrap-vue'

    export default {
        components: {
            BDropdown,
            BDropdownItem,
            BDropdownDivider
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        methods: {
            openInNewTab() {
                window.open(`terminal/${  this.data.id}`, '_blank')
            },
            getScreenshot() {
                window.open(`api/terminal/v1/terminal/${  this.data.id}/screenshot`, '_blank')
            },
            openShell() {
                this.$router.push({ name: 'shell', params: {terminalId: this.data.id }})
            },
            unassignTerminal() {
                const thisIns = this
                const removePromise = this.$http.put(`/api/management/v1/terminal/${  this.data.id}/assign/${  this.nullObjectId}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Terminal unassigned')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.$emit('dataChanged')
                })
            },
            rebootTerminal() {
                const thisIns = this
                const rebootPromise = this.$http.get(`/api/terminal/v1/terminal/${  this.data.id  }/reboot`)
                rebootPromise.then(function() {
                    thisIns.$printSuccess('Reboot request sent')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            removeTerminal() {
                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/terminal/${  this.data.id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Terminal removed')
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.$emit('dataChanged')
                })
            }
        }
    }
</script>